<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <c-text
            label="CAS.No"
            name="casNo"
            placeholder="CAS.No"
            v-model="searchParam.casNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <c-text
            label="KE.No"
            name="keNo"
            placeholder="KE.No"
            v-model="searchParam.keNo">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="materialTable"
      title="자재 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="materialCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn v-if="editable" label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-material-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'single',
        plantCd: '',
        processCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS.No',
            align: 'center',
            sortable: true,
          },
          {
            name: 'keNo',
            field: 'keNo',
            label: 'KE.No',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        materialName: '',
        keNo: '',
        casNo: '',
        plantCd: '',
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.mam.chem.list.url;
      
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      if (this.popupParam.processCd) {
        this.searchParam.processCd = this.popupParam.processCd;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '화학자재를 선택하세요.', // 자재를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
